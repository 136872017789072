import {useEffect, useState} from "react";

export function useJsonFetch<T>(path: string, initialState: T) {
	const [data, setData] = useState<T>(initialState);

	useEffect(() => {
		fetch(path).then(r => r.json()).then(setData);
	}, [path]);

	return data;
}
