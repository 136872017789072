import React, { FC, useMemo } from 'react';
import {
	Button,
	ButtonContainer,
	CloseIcon,
	InfoblockImage, InfoblockVideo,
	InfoBox,
	LeftArrow,
	Overlay,
	RightArrow,
} from '@erm-tasku/components';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../contexts/AppContext';

export const InfoBlock: FC = ({ children }) => {
	const { t } = useTranslation();
	const { selectedMarker,selectMarker, selectedMarkerMedia, selectNextMarker, selectPreviousMarker } = useAppContext();
	const visible = useMemo(() => !!selectedMarker, [selectedMarker]);

	return (
		<Overlay>
			<InfoBox visible={visible}>
				<h1>{t([`${selectedMarker?.id}.pealkiri`, ''])}</h1>
				{t([`${selectedMarker?.id}.tekst`, ''])}
				{selectedMarkerMedia?.media.type === 'image' && (
					<InfoblockImage src={`/assets/media/${selectedMarkerMedia.media.path}`}/>
				)}

				{selectedMarkerMedia?.media.type === 'video' && (
					<InfoblockVideo src={`/assets/media/${selectedMarkerMedia.media.path}`}/>
				)}

			</InfoBox>
			<ButtonContainer>
				{visible && (
					<>
						<Button onClick={() => selectMarker(null)}>
							<CloseIcon aria-label={t('ui.close')} />
						</Button>
						<Button onClick={selectNextMarker}>
							<RightArrow aria-label={t('ui.next')} />
						</Button>
						<Button onClick={selectPreviousMarker}>
							<LeftArrow aria-label={t('ui.previous')} />
						</Button>
					</>
				)}
			</ButtonContainer>
		</Overlay>
	);
};
