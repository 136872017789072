import React, { useEffect, useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import { AnimationMixer, LoopRepeat } from 'three';
import { useFrame } from '@react-three/fiber';

interface GLTFModelProps {
	path: string;
	draco?: boolean;
	position?: Vector3Like | number[];
	scale?: number;
}

export const GLTFModel: React.FC<GLTFModelProps> = (props) => {
	return (
		// <Suspense fallback={null}>
		<GLTRFModelRaw {...props} />
		// </Suspense>
	);
};

const GLTRFModelRaw: React.FC<GLTFModelProps> = ({ path, position = [0, 0, 0], draco = true, scale = 1 }) => {
	const { scene, animations } = useGLTF(path, draco);

	const mixer = useMemo(() => new AnimationMixer(scene), [scene]);

	useFrame((state, delta) => {
		mixer.setTime(mixer.time + delta);
	});

	useEffect(() => {
		if (animations.length) {
			const animation = animations[0];
			const action = mixer.clipAction(animation);
			action.setLoop(LoopRepeat, Infinity);
			action.play();
		}
	}, [animations, scene, mixer]);

	return <primitive object={scene} position={position} scale={scale} />;
};
