import React, { createContext, FC, useContext } from 'react';
import { useCameraPath } from '../util/hooks/useCameraPath';
import { useInputInertiaLoop } from '../util/hooks/useInputInertiaLoop';
import { Curve, Vector3 } from 'three';

interface CameraDollyContextProps {
	cameraCurve: Curve<Vector3> | null,
	targetCurve: Curve<Vector3> | null,
	distance: number;
}

export const CameraDollyContext = createContext<CameraDollyContextProps>({} as CameraDollyContextProps);

export const CameraDollyContextProvider: FC = ({ children }) => {
	const cameraCurve = useCameraPath('/assets/camera-path.json', { scale: 0.001 });
	const targetCurve = useCameraPath('/assets/camera-target.json', { scale: 0.001 });
	const distance = useInputInertiaLoop();

	return <CameraDollyContext.Provider children={children} value={{cameraCurve, targetCurve, distance}} />;
};

export const useCameraDollyContext = () => useContext(CameraDollyContext);
