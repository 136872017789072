import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {load as loadYaml} from 'js-yaml';
import Backend from 'i18next-http-backend';

function getDefaultLang(): string {
	const params = (new URL(window.location.href)).searchParams;
	return params.get('lang') || 'et';
}

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		lng: getDefaultLang(),
		backend: {
			loadPath: '/assets/locales/{{lng}}.yml',
			parse: (data) => {
				return  loadYaml(data) as string;
			}
		},
		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		keySeparator: '__',
		returnEmptyString: true,
		react: {
			wait: true,
			useSuspense: false,
		},
		fallbackLng: false,
		initImmediate: false,
	})
	.catch(console.error);

export default i18n;
