import { useInertiaLoop } from './useInertiaLoop';
import { useDrag, useWheel } from 'react-use-gesture';

export function useInputInertiaLoop() {
	const { position, setForce } = useInertiaLoop();

	useDrag(
		(ev) => {
			const [vx] = ev.vxvy;
			setForce(vx, ev.dragging || ev.velocity < 0.5);
		},
		{ domTarget: window }
	);

	useWheel(
		(ev) => {
			const vx = ev.delta[1];
			setForce(vx / -50);
		},
		{ domTarget: window }
	);

	return position
}
