import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import { useJsonFetch } from '../util/hooks/useJsonFetch';
import { MathUtils } from 'three';

const {euclideanModulo} = MathUtils;

export interface AppContextProps {
	markers: MarkerPosition[];
	selectedMarker: MarkerPosition | null;
	selectedMarkerMedia: MarkerMedia | null;
	selectMarker: (marker: MarkerPosition | null) => void;
	selectNextMarker: () => void;
	selectPreviousMarker: () => void;
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const AppContextProvider: FC = ({ children }) => {
	const [selectedMarker, setSelectedMarker] = useState<MarkerPosition | null>(null);
	const markers = useJsonFetch<MarkerPosition[]>('/assets/marker-positions.json', []);
	const markersMedia = useJsonFetch<MarkerMedia[]>('/assets/marker-media.json', []);
	
	const selectedMarkerMedia = useMemo(() => markersMedia.find(item => item.id === selectedMarker?.id) || null, [markersMedia, selectedMarker]);

	function selectMarker(marker: MarkerPosition | null) {
		setSelectedMarker(marker);
	}

	function selectMarkerOffset(offset: number) {
		if (!selectedMarker) return;
		const currentIndex = markers.indexOf(selectedMarker);
		const nextIndex = euclideanModulo(currentIndex + offset, markers.length - 1);
		selectMarker(markers[nextIndex]);
	}

	function selectNextMarker() {
		selectMarkerOffset(1);
	}

	function selectPreviousMarker() {
		selectMarkerOffset(-1);
	}

	return <AppContext.Provider children={markers ? children : null} value={{
		markers,
		selectedMarker,
		selectedMarkerMedia,
		selectMarker,
		selectNextMarker,
		selectPreviousMarker
	}} />;
};

export const useAppContext = () => useContext(AppContext);
