import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { useCameraDollyContext } from '../contexts/CameraDollyContext';

export const PathCamera: React.FC = () => {
	const { cameraCurve, targetCurve, distance } = useCameraDollyContext();
	const camera = useThree((state) => state.camera)

	// useFrame(({ camera }) => {
	// 	if (cameraCurve && targetCurve) {
	// 		camera.position.set(...(cameraCurve.getPointAt(distance).toArray() as Vector3Like));
	// 		camera.lookAt(targetCurve.getPointAt(distance));
	// 	}
	// });

	useEffect(() => {
		if (cameraCurve && targetCurve) {
			camera.position.set(...(cameraCurve.getPointAt(distance).toArray() as Vector3Like));
			camera.lookAt(targetCurve.getPointAt(distance));
		}
	}, [camera, distance, cameraCurve, targetCurve]);

	return null;
};
