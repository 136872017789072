import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`	
	body {
		font-family: var(--body-font);
		overflow: hidden;
		
		-webkit-touch-callout: none;
		-webkit-user-select: none;
	}
`;
