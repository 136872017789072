
import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { FullscreenToggle, GlobalButtonContainer } from '@erm-tasku/components';
import { InfoBlock } from './components/InfoBlock';
import { GlobalStyles } from '@erm-tasku/styles';
import { GlobalStyles as AppStyles } from './styles/GlobalStyles';

import '@erm-tasku/components/lib/bundle.css';
import { useTranslation } from 'react-i18next';
import { useAppContext } from './contexts/AppContext';
import { Color, Vector3 } from 'three';
import { GLTFModel } from './components/GLTFModel';
import { PathCamera } from './components/PathCamera';
import { Markers } from './components/Markers';
import { CameraDollyContextProvider } from './contexts/CameraDollyContext';
import { Loader } from '@react-three/drei';

const Container = styled.div`
	position: relative;
`;

const fogColor = new Color('#002a41');
const moonlightColor = new Color().setHSL(0.5, 1.0, 0.22);

function App() {
	const { markers, selectMarker } = useAppContext();
	const { t } = useTranslation();

	const offset = [33.45,2.1,4.3]

	return (
		<Container>
			<GlobalStyles />
			<AppStyles />
			<GlobalButtonContainer>
				<FullscreenToggle exitLabel={t(`ui.fullscreenExit`)} enterLabel={t(`ui.fullscreen`)} />
			</GlobalButtonContainer>
			<Canvas
				style={{ width: '100wv', height: '100vh', background: '#002a41', touchAction: 'none' }}
				camera={{ fov: 75, position: new Vector3(0, 1000, 0) }}
				onPointerMissed={() => selectMarker(null)}
			>
				<Suspense fallback={null}>
					<ambientLight name={'ambient'} intensity={1} color={'#ffffff'} />
					<directionalLight name={'direct'} intensity={1} position={[20, 20, 20]} color={'#ffffff'} />
					<pointLight
						name={'moonlight'}
						intensity={5}
						position={[50, 0, 0]}
						color={moonlightColor}
					/>

					<fog attach={'fog'} color={fogColor} near={60} far={90} />

					<Markers markers={markers} onClick={selectMarker} />
					<CameraDollyContextProvider>
						<PathCamera />
					</CameraDollyContextProvider>

					{/*<GLTFModel path={'/assets/models/alusmudel.glb'} position={offset} />*/}
					<GLTFModel path={'/assets/models/buildings.glb'} />
					<GLTFModel path={'/assets/models/terrain.glb'} />
					<GLTFModel path={'/assets/models/trees.glb'} />
					<GLTFModel path={'/assets/models/hobused.glb'} position={offset} />
					<GLTFModel path={'/assets/models/kanad.glb'} position={offset} />
					<GLTFModel path={'/assets/models/koer.glb'} position={offset} />
					<GLTFModel path={'/assets/models/lapsed.glb'} position={offset} />
					<GLTFModel path={'/assets/models/naine_laps.glb'} position={offset} />
					<GLTFModel path={'/assets/models/sepad.glb'} position={offset} />
					<GLTFModel path={'/assets/models/solgiauk.glb'} position={offset} />
					<GLTFModel path={'/assets/models/valvur_soob.glb'} position={offset} />
					<GLTFModel path={'/assets/models/valvur_valvab.glb'} position={offset} />
					<GLTFModel path={'/assets/models/vestlus.glb'} position={offset} />
					<GLTFModel path={'/assets/models/ketramine.glb'} position={offset} />
				</Suspense>
				{/*<OrbitControls target={[25, 5, 0]} autoRotate={true} autoRotateSpeed={-1} />*/}
			</Canvas>
			<Loader />

			<InfoBlock />
		</Container>
	);
}

export default App;
