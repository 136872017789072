import React, { useRef, useMemo, useState } from 'react';
import { BufferGeometry, Color, Mesh, Vector3 } from 'three';
import { useFrame } from '@react-three/fiber';
import { Plane } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events';

interface MarkersProps {
	markers: MarkerPosition[];
	onClick?: (marker: MarkerPosition) => void;
}

export const Markers: React.FC<MarkersProps> = ({ markers, onClick }) => {
	const markerComponents = useMemo(() => {
		return markers?.map((marker, index) => {
			const { x, y, z } = marker;
			return (
				<Marker key={index} position={new Vector3(x * 0.001, y * 0.001, z * 0.001)} onClick={() => onClick?.(marker)} />
			);
		});
	}, [markers, onClick]);

	return <>{markerComponents}</>;
};

interface MarkerProps {
	position: Vector3;
	onClick?: (event: ThreeEvent<MouseEvent>) => void;
}

const initialColor = new Color(255, 0, 0);
const lerpColor = new Color(255, 255, 255);

export const Marker: React.FC<MarkerProps> = ({ position, onClick }) => {
	const ref = useRef<Mesh>();
	const [hover, setHover] = useState<boolean>(false);

	const geometry = useMemo(() => {
		const geometry = new BufferGeometry();
		geometry.setFromPoints([new Vector3(0.5, 0.86, 0), new Vector3(-0.5, 0.86, 0), new Vector3(0, 0, 0)]);
		geometry.computeVertexNormals();
		return geometry;
	}, []);

	useFrame(({ camera, clock }) => {
		ref.current?.setRotationFromEuler(camera.rotation);
	});

	return (
		<Plane
			ref={ref}
			position={position}
			onClick={onClick}
			scale={[1.5, 1.5, 1.5]}
			onPointerOver={() => setHover(true)}
			onPointerOut={() => setHover(false)}
		>
			<meshBasicMaterial attach={'material'} transparent={true} opacity={0} />

			<mesh geometry={geometry} scale={[0.25, 0.25, 0.25]}>
				<meshBasicMaterial attach={'material'} color={hover ? initialColor : lerpColor} />
			</mesh>
		</Plane>
	);
};
