import { useEffect, useState } from 'react';
import { CatmullRomCurve3, Curve, Vector3 } from 'three';

interface CameraPathOptions {
	scale?: number;
}

export function useCameraPath(path: string, options?: CameraPathOptions) {
	const [curve, setCurve] = useState<Curve<Vector3> | null>(null);

	useEffect(() => {
		fetch(path)
			.then((r) => r.json())
			.then((r) => r.map(([x, y, z]: Vector3Like) => new Vector3(x, y, z).multiplyScalar(options?.scale ?? 1)))
			.then((vectorArray) => {
				return new CatmullRomCurve3(vectorArray, true);
			})
			.then(setCurve);
	}, [options?.scale, path]);

	return curve;
}
