import { useState } from 'react';
import { MathUtils } from 'three';
import { useFrame } from '@react-three/fiber';

export function useInertiaLoop() {
	const [position, setPanPosition] = useState(0);
	const { setForce } = useInertia(setPanByOffset);

	function setPanByOffset(offset: number) {
		const rawPosition = position + (offset || 0) / 1000;
		const newPos = MathUtils.euclideanModulo(rawPosition, 1);
		setPanPosition(newPos);
	}

	return { position, setForce };
}

type InertiaUpdateCallback = (velocity: number) => void;

export function useInertia(callback: InertiaUpdateCallback) {
	const [velocity, setVelocity] = useState(0);
	const [useInertia, setUseInertia] = useState(false);

	useFrame((state, delta) => {
		tick(delta);
	});

	function tick(delta: number = 0.16) {
		if (useInertia) {
			setVelocity((prevState) => MathUtils.damp(prevState, 0, 0.9, delta));
			callback(velocity);
		}
	}

	function setForce(force: number, immediate?: boolean) {
		setVelocity((prevState) => force);

		setUseInertia(!immediate);

		if (immediate) {
			callback(force);
		}
	}

	return { setForce, velocity };
}
